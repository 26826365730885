import { useState } from "react";
import { ITask, ITaskService } from "../../../service";
import { id } from "../../test-ids";
import Checkbox from "@mui/material/Checkbox";
import Icon from "@mui/material/Icon";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import Fab from "@mui/material/Fab";

interface ITaskListItemProps {
  task: ITask;
  index: number;
  taskService: ITaskService;
  onDeleted(task: ITask): void;
}

export function TaskListItem({
  task: theTask,
  index,
  taskService,
  onDeleted,
}: ITaskListItemProps) {
  const [task, setTask] = useState(theTask);
  const [isDisabled, setIsDisabled] = useState(false);
  const [updateStatusError, setUpdateStatusError] = useState<Error | null>(
    null
  );
  const [isChecked, setIsChecked] = useState<any>(task.status === "complete");
  const [isDeleting, setIsDeleting] = useState<boolean>(false);
  const [deletionErrorResponse, setDeletionErrorResponse] =
    useState<null | Error>(null);

  const taskTestId = id().taskListItem(index);
  const checkboxTestId = id().taskListItemCheckbox(index);
  const deleteTestId = id().taskListItemDeleteIcon(index);
  const deleteErrorTestId = id().taskListItemDeleteError(index);
  const updateStatusErrorTestId = id().updateTaskError(index);

  if (isDeleting) {
    return <ListItem data-testid={taskTestId}>Deleting...</ListItem>;
  }

  return (
    <ListItem data-testid={taskTestId}>
      <span>
        <Checkbox
          id={checkboxTestId}
          disabled={isDisabled}
          checked={isChecked}
          onChange={async () => {
            const newStatus = getNextCheckedStatus(task);

            setIsDisabled(true);

            const response = await taskService.updateTaskStatus({
              task,
              newStatus,
            });

            if (response instanceof Error) {
              setUpdateStatusError(response);
            } else {
              setUpdateStatusError(null);
              setTask(response);
            }

            setIsDisabled(false);
            setIsChecked(!isChecked);
          }}
          inputProps={{
            // @ts-ignore
            "data-testid": checkboxTestId,
          }}
        />
      </span>
      <ListItemText>{task.title}</ListItemText>

      {updateStatusError && (
        <span data-testid={updateStatusErrorTestId}>
          {updateStatusError.message}
        </span>
      )}
      {deletionErrorResponse && (
        <span data-testid={deleteErrorTestId}>
          {deletionErrorResponse.message}
        </span>
      )}

      <Fab color="secondary" size="small" variant="extended" aria-label="add">
        <Icon
          component={(props: any) => (
            <span {...props} className="cursor-pointer">
              Delete
            </span>
          )}
          data-testid={deleteTestId}
          onClick={async () => {
            if (isDisabled) {
              return;
            }
            setIsDeleting(true);
            const response = await taskService.deleteTask(task);
            setIsDeleting(false);

            if (response instanceof Error) {
              setDeletionErrorResponse(response);
            } else {
              onDeleted(task);
            }
          }}
        />
      </Fab>
    </ListItem>
  );
}

function getNextCheckedStatus(task: ITask): string {
  if (task.status === "open") {
    return "complete";
  }

  return "open";
}
