import BaseCard from "@mui/material/Card";

export interface ICardProps {
  style: React.CSSProperties
  children: JSX.Element[]
}

export function Card(props: ICardProps): JSX.Element {
  return (
    <BaseCard
      style={props.style}
      children={props.children}
    />
  )
}
