import BaseButton from "@mui/material/Button";

export interface IButtonProps {
  disabled?: boolean
  testid?: string
  type?: "button" | "reset" | "submit"
  onClick?: React.MouseEventHandler<HTMLButtonElement>
  children: React.ReactNode
}

export function Button(props: IButtonProps): JSX.Element {
  return (
    <BaseButton
      disabled={props.disabled}
      data-testid={props.testid}
      type={props.type}
      onClick={props.onClick}
      children={props.children}
    />
  )
}
