import "./index.css";
import React from "react";
import ReactDOM from "react-dom/client";
import { reportWebVitals } from "./report-web-vitals";
import { TaskService } from "./service";
import { TaskListApp } from "./task-list-app";


const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement,
);

const taskService = new TaskService(localStorage);

root.render(
  <React.StrictMode>
    <TaskListApp taskService={taskService} />
  </React.StrictMode>,
);

reportWebVitals();
