import { ITaskService, ITask, toTaskKey } from "../../service";
import { isEmpty } from "../../utility";
import { TaskListItem } from "./task-list-item";
import { id } from "../test-ids";
import List from "@mui/material/List"

interface ITaskListProps {
  response: ITask[] | Error;
  taskService: ITaskService;
  isLoading: boolean;
  onTaskDeleted(task: ITask): void;
}

export function TaskList({ response, taskService, isLoading, onTaskDeleted }: ITaskListProps): JSX.Element {
  if (isLoading) {
    return <TaskListLoader isLoading={isLoading} />;
  }

  if (response instanceof Error) {
    return <div data-testid={id().taskListError}>{response.message}</div>;
  }

  if (isEmpty(response)) {
    return <div data-testid={id().taskList}>No tasks at this moment</div>;
  }

  return (
    <List data-testid={id().taskList}>
      {response
        .filter(Boolean)
        .map((t: any, index: number) => (
          <TaskListItem key={toTaskKey(t)} onDeleted={onTaskDeleted} task={t} index={index} taskService={taskService} />
        ))}
    </List>
  );
}

interface ITaskListLoaderProps {
  isLoading: boolean;
}

export function TaskListLoader({ isLoading }: ITaskListLoaderProps): JSX.Element {
  if (!isLoading) {
    return <></>;
  }

  return <div data-testid={id().listLoader}>Loading your tasks...</div>;
}
