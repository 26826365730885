export function id() {
    const _id = (...extra: string[]) => ["task-list", ...extra].join(".");
  
    const taskListItem = (index: number) => _id(`task-list-item[${index}]`);
  
    return {
      listLoader: _id("list-loader"),
      appTitle: _id("app-title"),
      createTaskButton: _id("create-task-button"),
      createTaskError: _id("create-task-error"),
      createTaskLoader: _id("create-task-loader"),
      taskListError: _id("task-list-error"),
      updateTaskError: (index: number) => _id("update-task-error") + `[${index}]`,
      taskList: _id("task-list"),
      taskListItemCheckbox: (index: number) => taskListItem(index) + ".checkbox",
      taskListItemDeleteIcon: (index: number) => taskListItem(index) + ".delete-icon",
      taskListItemDeleteError: (index: number) => taskListItem(index) + ".delete-error",
      taskListItem,
    };
  }