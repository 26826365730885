export function isEmpty(x: unknown): boolean {
  if (x === undefined) {
    return true;
  }

  if (x === null) {
    return true;
  }

  if (Array.isArray(x)) {
    return x.length === 0;
  }

  return false;
}
