import { slugify } from "../../utility";

export interface ITask {
  title: string;
  status: string;
}

interface IUpdateTaskStatusInput {
  task: ITask;
  newStatus: string;
}

export interface ITaskService {
  createTask(input: ICreateTaskInput): Promise<ITask | Error>;
  listTasks(): Promise<ITask[] | Error>;
  updateTaskStatus(input: IUpdateTaskStatusInput): Promise<ITask | Error>;
  deleteTask(task: ITask): Promise<true | Error>;
}

interface ICreateTaskInput {
  title: string;
}

interface IStorage {
  setItem(key: string, value: string): void;
  getItem(key: string): string | null;
  removeItem(key: string): void;
}

export class TaskService implements ITaskService {
  constructor(private storage: IStorage) {}

  deleteTask = async (task: ITask): Promise<true | Error> => {
    this.storage.removeItem(toTaskKey(task));

    return true;
  };

  listTasks = async (): Promise<ITask[]> => {
    const tasks = [];
    for (const key in this.storage) {
      if (!/^tasks\?title=.+$/.test(key)) {
        continue;
      }

      const entryString = this.storage.getItem(key);

      if (!entryString) {
        continue;
      }

      const task = JSON.parse(entryString);

      tasks.push(task);
    }

    return tasks;
  };

  updateTaskStatus = async (input: IUpdateTaskStatusInput): Promise<ITask | Error> => {
    const error = (reason: string) => new Error(`Cannot update task status: ${reason}.`);

    if (typeof input !== "object" || input === null) {
      return error("input must be an object");
    }

    const { task, newStatus } = input;

    if (typeof task !== "object" || task === null) {
      return error("task must be an object");
    }

    if (typeof task.title !== "string") {
      return error("task title must be a string");
    }

    if (typeof task.status !== "string") {
      return error("task status must be a string");
    }

    if (typeof newStatus !== "string") {
      return error("new task status must be a string");
    }

    const updatedEntry = {
      ...input.task,
      status: newStatus,
    };

    const updatedEntryString = JSON.stringify(updatedEntry);

    this.storage.setItem(toTaskKey(input.task), updatedEntryString);

    return updatedEntry;
  };

  createTask = async (input: ICreateTaskInput) => {
    const error = (reason: string) => new Error(`Cannot create task: ${reason}.`);

    if (typeof input !== "object" || input === null) {
      return error("task must be an object");
    }

    if (typeof input.title !== "string") {
      return error("task title must be a string");
    }

    const trimmedTitle = input.title.trim();

    if (trimmedTitle === "") {
      return error("title must not be empty");
    }

    const cleansedInput = {
      title: trimmedTitle,
      status: "open",
    };

    const key = toTaskKey(cleansedInput);

    if (this.storage.getItem(key)) {
      return error("task with title already exists");
    }

    this.storage.setItem(key, JSON.stringify(cleansedInput));

    return cleansedInput;
  };
}

export function toTaskKey(t: ITask) {
  return `tasks?title=${slugify(t.title)}`;
}
