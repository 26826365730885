import { FormEvent, useEffect, useState } from "react";
import { ITaskService } from "../service";
import { TaskList } from "./task-list";
import { id } from "./test-ids";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import { Button, Card } from "../ui-kit";

export interface IAppProps {
  taskService: ITaskService;
}

export function TaskListApp({ taskService }: IAppProps) {
  const [title, setTitle] = useState("");
  const [loadingTasks, setLoadingTasks] = useState(false);
  const [tasks, setTasks] = useState<any>([]);
  const [isCreatingTask, setIsCreatingTask] = useState(false);
  const [createTaskError, setCreateTaskError] = useState<any>(null);

  useEffect(() => {
    async function loadTasks() {
      setLoadingTasks(true);

      const response = await taskService.listTasks();
      setTasks(response);

      setLoadingTasks(false);
    }

    loadTasks();
  }, [taskService]);

  const handleSubmit = async (
    event: FormEvent<HTMLFormElement> | FormEvent<HTMLButtonElement>
  ) => {
    event.preventDefault();

    setIsCreatingTask(true);

    const response = await taskService.createTask({ title });

    if (response instanceof Error) {
      setCreateTaskError(response);
    } else {
      setTasks([response, ...tasks]);
      setCreateTaskError(null);
      setTitle("");
    }
    setIsCreatingTask(false);
  };

  return (
    <Grid
      container
      spacing={0}
      direction="column"
      alignItems="center"
      justifyContent="center"
      sx={{ minHeight: "100vh" }}
    >
      <Grid item xs={12} sm={12} md={12}>
        <Card style={{ minWidth: '320px' }}>
          <Typography data-testid={id().appTitle}>Task List</Typography>
          <form
            onSubmit={handleSubmit}
          >
            <TextField
              inputRef={input => input && input.focus()}
              variant="standard"
              label="Enter Task Title"
              error={createTaskError ? true : false}
              style={{
                minWidth: "300px",
              }}
              helperText={
                createTaskError ? (
                  <span data-testid={id().createTaskError}>
                    {createTaskError.message}
                  </span>
                ) : undefined
              }
              disabled={isCreatingTask}
              value={title}
              onChange={(e) => {
                const value = e.target.value;
                setTitle(value);
              }}
            />
            <br />
            <Button
              disabled={loadingTasks || isCreatingTask}
              testid={id().createTaskButton}
              type="submit"
              onClick={handleSubmit}
            >
              Create
            </Button>
          </form>
          <CreateTaskLoader creatingTask={isCreatingTask} />
          <TaskList
            response={tasks}
            onTaskDeleted={(task) => {
              const currentTasks = [...tasks];

              setTasks(currentTasks.filter((t) => t.title !== task.title));
            }}
            taskService={taskService}
            isLoading={loadingTasks}
          />
        </Card>
      </Grid>
    </Grid>
  );
}

interface ICreateTaskLoaderProps {
  creatingTask: boolean;
}

function CreateTaskLoader({ creatingTask }: ICreateTaskLoaderProps) {
  if (!creatingTask) {
    return <></>;
  }
  return <div data-testid={id().createTaskLoader}>Creating Task</div>;
}

